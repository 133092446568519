import * as React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";


export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="flex-column main-content">
          <div
            className="full-width-image-container margin-top-0 main-image"
            style={{
              backgroundImage: `url('/img/path.jpg')`,
            }}
          >
            <h1
              className="has-text-weight-bold is-size-3"
              style={{
                backgroundColor: "whitesmoke",
                color: "black",
                padding: "1rem",
                position: "relative",
                bottom: "4rem"
              }}
            >
              Latest Stories
            </h1>
          </div>
          <section className="section main-text">
            <div className="container">
              <div className="content">
                <BlogRoll />
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}
